<template>
   

    <div class="content-cycle-container">
        <table>
           
            <tr v-if="selectedCourse && selectedCourse.hasCohorts">
                <th><span>Cohort:</span></th>
                <th>
                    <Dropdown :options="cohortOptions" optionLabel="cohortName" v-model="selectedCohortOption" :loading="cohortsLoading"/>
                </th>
            </tr>
           
        </table>
    </div>

    
    <CohortStats  :miniVersion="isMobile" :selectedGroup="selectedGroup" :selectedCourse="selectedCourse"
            :selectedFeed="selectedFeed" :selectedCohort="selectedCohortOption"  />
    

    
</template>

<script>
import CohortStats from './CohortStats.vue';

import Dropdown from 'primevue/dropdown';

import GroupService from '../../../service/GroupService';

import BrowserUtils from '../../../utilities/BrowserUtils';
import UserUtils from '../../../utilities/UserUtils';

const statisticsAndQuestionsLabels = {
    statistics: 'Statistics',
    questions: 'Questions'
}

export default {
    name: 'CourseProgress',
    props: {
        selectedGroup: {
            type: Object,
            required: false
        },
        selectedFeed: {
            type: Object,
            required: false
        },
        
        selectedCourse: {
            type: Object,
            required: false
        },
        
    },
    components: {
        CohortStats,
       
        Dropdown
    },
    data() {
        return {
            cohortsLoading: true,
            contests: [],
            selectedContest: null,

            contestsMenuItems: [],
            feedMenuItems: [
                { label: 'Questions', action: () => console.log("hi"), selected: true },
            ],

            selectedCycleOption: {},
            cycleOptions: [],

            statisticsAndQuestionsActiveIndex: 0,
            statisticsAndQuestionsItems: [
                { index: 0, label: statisticsAndQuestionsLabels.statistics, selected: true },
                { index: 1, label: statisticsAndQuestionsLabels.questions, selected: false },
            ],
            cohortOptions: [],
            selectedCohortOption: null,
        }
    },

    watch: {
        selectedFeed() {
            this.fetchCohorts();
        },
         selectedCourse() {
            this.fetchCohorts();
        },
    },

    computed: {
        isMobile() {
            return BrowserUtils.isMobile();
        },

       

      
    },

    mounted() {

        this.fetchCohorts();

    },

    methods: {

        viewStatisticsAndQuestions(name) {
            this.statisticsAndQuestionsActiveIndex = statisticsAndQuestionsLabels.statistics === name ? 0 : 1;
        },


        
      
        fetchCohorts() {
            this.cohortsLoading = true;
            if( UserUtils.isGroupOwnerAdminOrMod(this.selectedGroup.groupOwnerId, this.$store.getters['users/groupRoleList']) ) {
                 GroupService.getAllCourseCohorts(this.selectedCourse, 20, 0).then(resp => {
                    if( resp.data.status == "success"){
                        this.cohortOptions = resp.data.cohorts;
                        if( this.cohortOptions !=null && this.cohortOptions.length > 0 ) {
                            this.selectedCohortOption = this.cohortOptions[0];
                           
                        }
                    }
                    else {
                        console.log("error getting all course cohorts:"+resp.data.message);
                    }
                    this.cohortsLoading = false;
                });
            }
            else {
                GroupService.getUserCourseCohorts(this.selectedCourse).then(resp => {
                        if( resp.data.status == "success"){
                            this.cohortOptions = resp.data.cohorts;
                            if( this.cohortOptions !=null && this.cohortOptions.length > 0 ) {
                                this.selectedCohortOption = this.cohortOptions[0];
                               
                            }
                        }
                        else {
                            console.log("error getting user course cohorts:"+resp.data.message);
                        }
                        this.cohortsLoading = false;
                    })
            }


            
        },

    }
};
</script>

<style>
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background: #32364e !important;
    color: white !important;
}
</style>

<style scoped>
*:not(.pi),
::v-deep(.p-component) {
    font-family: "Trebuchet MS", "Verdana";
}

::v-deep(.scroll-to-top-button) {
    right: 18vw;
}

::v-deep(.p-dropdown) {
    border: 2px solid #32364E;
    border-radius: 50px;
    padding: 2px;
}

::v-deep(.p-dropdown:hover, .p-dropdown:active, .p-dropdown:focus, .p-dropdown.p-focus, .p-inputwrapper-focus) {
    border-color: #32364e;
}

::v-deep(.p-dropdown-label) {
    color: #32364e;
    font-size: 1rem;
    padding: 2px 0px 2px 4px;
}

::v-deep(.p-dropdown-trigger) {
    width: 1.5rem;
}

::v-deep(.p-dropdown-trigger-icon) {
    color: #32364e;
    font-size: 0.9rem;
}

.p-dropdown:not(.p-disabled).p-focus,
::v-deep(.p-dropdown:not(.p-disabled):focus) {
    outline: none !important;
    box-shadow: none !important;
    border-color: unset;
}

.content-cycle-container {
    margin-bottom: 8px !important;
    text-align: left;
}

@media (max-width: 760px) {

    .content-cycle-container {
        margin-bottom: 8px;
    }

    .main-menu-bar {
        margin: 1rem 1rem 1rem 1rem;
    }

    .card {
        border-radius: 0;
        margin-bottom: 2px;
        padding: 0 16px;
    }

    ::v-deep(.p-tabmenu .p-tabmenu-nav .p-tabmenuitem button) {
        background: #ffffff;
        border: solid #ffffff;
        border-width: 0 0 2px 0;
        padding: 6px;
    }

    ::v-deep(.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight button) {
        background: #ffffff;
        color: #33CC99;
        border: solid #33CC99;
        border-width: 0 0 2px 0;
        padding: 6px;
    }

    ::v-deep(.p-tabmenu-nav) {
        text-align: center;
        border-width: 0;
        display: flex;
        justify-content: space-around;
    }

    ::v-deep(.p-tabmenuitem) {
        padding-top: 0 !important;
    }
}
</style>