<template>
    <LoadingSpinner v-if="courseStatsLoading" :showLoading="courseStatsLoading" />
    <template v-else>
        <div v-if="miniVersion">
           
            <div v-if="userStats && userStats.length > 0">
                <span><b>Your Stats: </b></span>
                <span><b>
                        Rank:{{ userStats[0].ranking }} {{"#A:"+ userStats[0].numAnswered}} {{"#C:"+ userStats[0].numCorrect }} $R:{{ userStats[0].amountRewarded }}
                    </b></span>
            </div>
            <CourseStatsTable miniVersion v-if="courseStats?.allUserStats && courseStats?.allUserStats.length > 0"
                :loading="courseStatsTableLoading" @load-page="loadPage" :allUserStats="courseStats?.allUserStats"
                :selectedSortStatId="selectedSortStatId" :courseStatsLimit="courseStatsLimit"
                :totalStats="courseStats?.totalStats" @change-sort-order="changeSortOrder" />
        </div>
        <div v-else>
            <div >
                <div class="description-row">
                    <div v-if="userStats">
                        
                            <p>
                                {{"Total #Lessons: " +courseStats?.totalPosts }}
                            </p>
                            <p>
                                {{"Total #Questions: " +courseStats?.totalQuestionsAsked }}
                            </p>
                             <p>
                                {{"Total #Tasks: " +courseStats?.totalTasks }}
                            </p>
                        

                        <template v-if="userStats && userStats.length > 0 && userStats[0].analyst">
                            <p>{{"Your Progress:"}}</p>

                            <DataTable scrollable :value="userStats" tableClass="puzzle-stats">
                                <Column field="ranking" header="Rank" />
                                <Column header="Name" class="username-column">
                                    <template #body="slotProps">
                                        <span class="username-label" @click='goToAnalystProfile(slotProps.data.analyst.name)'
                                            v-tooltip.bottom="'@' + slotProps.data.analyst.name">
                                            @{{ slotProps?.data?.analyst?.name }}
                                        </span>
                                    </template>
                                </Column>
                               
                                <Column field="numAnswered" :header='"#Answered"'/>
                                <Column field="numCorrect" :header='"#Correct"' />
                                <Column header="$Rewards">
                                    <template #body="slotProps">
                                        {{ formatter.format(slotProps.data.amountRewarded, '$0.00') }}
                                    </template>
                                </Column>
                            </DataTable>
                        </template>
                    </div>
                    <div v-else>
                        <p> Stats not available.  You must be registered for the course to view stats.</p>
                    </div>
                  
                </div>
                
            </div>

            <div v-if="courseStats?.allUserStats && courseStats?.allUserStats.length > 0">
                <p>{{ "Cohort Progress:"}}</p>

                <CourseStatsTable  :loading="courseStatsTableLoading" @load-page="loadPage"
                    :allUserStats="courseStats?.allUserStats" :selectedSortStatId="selectedSortStatId"
                    :courseStatsLimit="courseStatsLimit" :totalStats="courseStats?.totalStats"
                    @change-sort-order="changeSortOrder" />
            </div>
        </div>
    </template>
</template>

<script>

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import LoadingSpinner from '../../common/LoadingSpinner.vue';

import router from '../../../router';

import FormattingUtils from '../../../utilities/FormattingUtils';
import GroupService from '../../../service/GroupService';
import EventBus from '../../../event-bus';
import BrowserUtils from '../../../utilities/BrowserUtils';
// import {mapState} from 'vuex';
import CourseStatsTable from './CourseStatsTable.vue';

const sortStatIds = {
    cumulativeReturn: 1,
    streak: 2,
    numAnswered: 3,
    numCorrect: 4,
    amountRewarded: 5,
};

export default {
    name: 'CohortStats',
    props: {
        miniVersion: {
            type: Boolean,
            required: false,
            default: false
        },
        
        selectedGroup: {
            type: Object,
            required: false
        },
         selectedCourse: {
            type: Object,
            required: false
        },
         selectedCohort: {
            type: Object,
            required: false
        },
        selectedFeed: {
             type: Object,
            required: false
        }
        
    },
    components: {
        CourseStatsTable,
        DataTable,
        Column,
        LoadingSpinner,
    },
    data() {
        return {
            formatter: new FormattingUtils(),

            userStats: null,
            courseStatsLoading: false,
            courseStatsTableLoading: false,
            courseStats: null,
            courseStatsLimit: BrowserUtils.isMobile() || this.selectedGroup ? 8 : 5,
            courseStatsOffset: 0,
            selectedSortStatId: sortStatIds.amountRewarded
        }
    },

    watch: {
        selectedCohort() {
            this.refresh();
        }
    },

    mounted() {
        // console.log("ContestStats mounted");
        this.refresh();
        this.subscribeToEvents();
    },

    activated() {
        // console.log("ContestStats activated");
        this.refresh();
        this.subscribeToEvents();
    },

    computed: {
       
    },

    methods: {

       
        goToAnalystProfile(username) {
            router.push('/analysts/profile/' + username);
        },

        subscribeToEvents() {
            EventBus.off('post-answered');
            EventBus.on('post-answered', activity => {
                if(activity?.contest?.contestId == this.contest?.contestId) {
                    this.refresh();
                }
            });
        },

        refresh() {
            this.courseStatsOffset = 0;
            this.fetchCourseStats();
        },

        loadPage(offset) {
            this.courseStatsOffset = offset;
            this.fetchCourseStats();
        },

        changeSortOrder(sortStatId) {
            this.selectedSortStatId = sortStatId;
            this.fetchCourseStats();
        },

        fetchCourseStats() {
            if( this.selectedCohort?.cohortId){
                this.courseStatsTableLoading = true;
                this.courseStatsLoading = true;
                const onResponse = resp => {
                    if (resp.data.status == "success") {
                        // console.log("getContestStats", resp);
                        this.courseStats = resp.data.stats;

                        this.userStats = this.courseStats?.userStats ? [this.courseStats?.userStats] : [];

                        if (this.miniVersion && this.courseStatsLoading && (!this.courseStats?.allUserStats || this.courseStats?.allUserStats.length == 0)) {
                            EventBus.emit('view-performance');
                        }
                    }

                    this.courseStatsTableLoading = false;
                    this.courseStatsLoading = false;
                };

            
                if( this.selectedFeed) {
                        GroupService.getCourseCohortModuleStats(this.selectedGroup.groupId, this.selectedCourse.courseId, this.selectedCohort.cohortId, this.selectedFeed.groupFeedId,
                        this.courseStatsLimit, this.courseStatsOffset, this.selectedSortStatId).then(onResponse);
                }
                else {
                    GroupService.getCourseCohortStats(this.selectedGroup.groupId, this.selectedCourse.courseId, this.selectedCohort.cohortId, 
                        this.courseStatsLimit, this.courseStatsOffset, this.selectedSortStatId).then(onResponse);
                }
            }
            
        },

    }
}
</script>

<style scoped>

::v-deep(.puzzle-stats) {
    width: 100% !important;
}
.card {
    margin-bottom: 1rem;
}

.card .description-row {
    display: flex;
    align-items: center;
}
.card .description-row .fintrivia-icon {
    width: 175px;
    margin-left: auto;
}

::v-deep(table th),
::v-deep(table td) {
    padding: 0.4rem 0.4rem !important;
}

::v-deep(.username-column) {
    width: 16.666%;
    max-width: 16.666%;
    overflow: hidden;
}

::v-deep(.p-datatable-sm) {
    margin-top: 8px;
}

.username-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.username-label:hover {
    color: #33CC99;
    cursor: pointer;
}
</style>